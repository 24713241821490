<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; max-width: 360px;"
    fluid
  >
    <v-card>
      <v-list>
        <v-list-item class="py-1">
          <v-select
            v-model="value.sortBy"
            hide-details
            :items="sortKeys"
            :label="$t('deconve.sortBy')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.sortOrder"
            hide-details
            :items="orderKeys"
            :label="$t('deconve.sortOrder')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <date-picker
            width="100%"
            :placeholder="$t('deconve.addedAt')"
            :date="value.createdAt"
            @input="onCreatedAtDateChanged"
          />
        </v-list-item>

        <v-list-item
          v-if="isOnDeconveHubWorkspace"
        >
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.workspace') }}
              </span>
            </v-row>
            <v-row
              v-if="!value.workspaceId"
              class="py-2"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openWorkspaceDialog"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addWorkspace }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.workspace') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <v-chip
                label
                outlined
                class="pa-1"
              >
                {{ value.workspaceName }}
              </v-chip>
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openWorkspaceDialog"
              >
                <v-icon>
                  {{ icons.addWorkspace }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item class="py-1">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.units') }}
              </span>
            </v-row>
            <v-row
              v-if="value.tags.length === 0"
              class="py-1 align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>
              <v-switch
                v-model="value.noTags"
                inset
                hide-details
                class="ma-0 ml-4"
                :label="$t('deconve.noTags')"
                @change="onFilterChanged"
              />
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="tagId in value.tags"
                :key="tagId"
                class="pa-1"
                :tag-id="tagId"
              />
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          :disabled="!hasChanged"
          :small="$vuetify.breakpoint.mobile"
          @click="clearFilters"
        >
          {{ $t('deconve.clearFilters') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <tags-manager-dialog ref="tagDialog" />
    <workspaces-dialog ref="workspaceDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mdiTagPlusOutline, mdiBriefcaseOutline } from '@mdi/js';

import DatePicker from '@/components/DatePicker.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import Tag from '@/components/Tag.vue';
import WorkspacesDialog from '@/components/WorkspacesDialog.vue';

import { PERSON_STATUS, SORT_ORDER } from '@/utils/faceidFilters';
import SCOPES from '@/utils/scopes';

export default {
  name: 'UnitFilter',
  components: {
    DatePicker,
    TagsManagerDialog,
    Tag,
    WorkspacesDialog,
  },
  props: {
    value: { type: Object, required: true },
    hasChanged: { type: Boolean, default: false },
  },
  data() {
    return {
      sortKeys: [
        { text: this.$t('deconve.personName'), value: 'name' },
        { text: this.$t('deconve.date'), value: 'created_at' },
      ],
      orderKeys: [
        { text: this.$t('deconve.ascendingOrder'), value: SORT_ORDER.ascending },
        { text: this.$t('deconve.descendingOrder'), value: SORT_ORDER.descending },
      ],
      showModeKeys: [
        { text: this.$t('deconve.allFemale'), value: PERSON_STATUS.all },
        { text: this.$t('deconve.actives'), value: PERSON_STATUS.activated },
        { text: this.$t('deconve.inTrash'), value: PERSON_STATUS.inTrash },
      ],
      icons: {
        addTag: mdiTagPlusOutline,
        addWorkspace: mdiBriefcaseOutline,
      },
    };
  },
  computed: {
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
  },
  methods: {
    clearFilters() {
      this.$emit('clear');
    },
    openWorkspaceDialog() {
      const options = {
        workspaceId: this.value.workspaceId,
        scopes: [SCOPES.READ_COM_DECONVE_FACEID_PERSON],
      };

      this.$refs.workspaceDialog.open(options).then((workspace) => {
        this.value.workspaceId = workspace?.id;
        this.value.workspaceName = workspace?.name;

        this.onFilterChanged();
      }).catch(() => {
        // User canceled the dialog
      });
    },
    openTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.tags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.tags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    onFilterChanged() {
      this.$emit('change');
    },
    onCreatedAtDateChanged(date) {
      this.value.createdAt = date;
      this.onFilterChanged();
    },
  },
};
</script>
